"use client";

import { CheckIcon } from "lucide-react";

import { licenses } from "@/config/pricing";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { CheckoutButton } from "@/components/checkout-button";
import { Icons } from "@/components/icons";

const plans = [
  // {
  //   priceId: "price_1PzNJSQPAIC9au8AaIRSedas",
  //   name: "Team License",
  //   description:
  //     "Beautifully crafted React + Tailwind CSS + Framer Motion components and templates for your team's next web project.",
  //   cta: "Get Access",
  //   coupon: "9aiybIpM",
  //   icon: Icons.logo,
  //   discountPrice: 349,
  //   isDiscounted: true,
  //   price: 599,
  //   isMostPop: false,
  //   featureHeader: "Everything in Personal, plus:",
  //   features: ["10 developer licenses"],
  // },
  {
    priceId: licenses.individualAnnual.priceId,
    name: licenses.individualAnnual.name,
    period: licenses.individualAnnual.period,
    description: licenses.individualAnnual.description,
    mode: licenses.individualAnnual.mode,
    cta: licenses.individualAnnual.cta,
    coupon: licenses.individualAnnual.coupon,
    icon: Icons.logo,
    discountPrice: 149,
    isDiscounted: true,
    price: 199,
    isMostPop: false,
    featureHeader: "What's included:",
    features: [
      "Over 50+ sections — Everything you need to build beautiful landing pages",
      "5+ templates — Templates built with React, Next.js, and TailwindCSS",
      // "Lifetime access — Get access to everything available today, plus everything added in the future",
      // "Lifetime updates — Get access to all future updates for the original purchase price",
      "Commercial Use — Use the templates for commercial projects. Re-selling of code is NOT allowed.",
      "Perpetual license — You own the templates, forever",
    ],
  },
  {
    priceId: licenses.individual.priceId,
    name: licenses.individual.name,
    period: licenses.individual.period,
    description: licenses.individual.description,
    mode: licenses.individual.mode,
    cta: licenses.individual.cta,
    coupon: licenses.individual.coupon,
    icon: Icons.logo,
    discountPrice: 199,
    isDiscounted: true,
    price: 249,
    isMostPop: true,
    featureHeader: "What's included:",
    features: [
      "Over 50+ sections — Everything you need to build beautiful landing pages",
      "5+ templates — Templates built with React, Next.js, and TailwindCSS",
      "Commercial Use — Use the templates for commercial projects. Re-selling of code is NOT allowed.",
      "Perpetual license — You own the templates, forever",
      "Lifetime access — Get access to everything available today, plus everything added in the future",
      "Lifetime updates — Get access to all future updates for the original purchase price",
    ],
  },
];

export default function Page() {
  return (
    <section id="pricing">
      <div className="relative container mx-auto px-4 py-16 max-w-7xl">
        <div className="mx-auto max-w-5xl text-center">
          <h4 className="text-xl font-bold tracking-tight text-foreground">
            Pricing
          </h4>

          <h2 className="text-4xl font-bold tracking-tight text-foreground sm:text-6xl">
            Simple pricing for unlimited access
          </h2>

          <p className="mt-6 text-lg leading-8 text-muted-foreground">
            Get unlimited access to our complete library of 50+ components and
            templates, plus all future updates and additions, with a single
            one-time purchase.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 xl:max-w-4xl xl:grid-cols-2">
          {plans.map((tier, tierIdx) => (
            <div
              key={tier.name}
              className={cn(
                tier.isMostPop
                  ? "relative bg-background shadow-2xl"
                  : "bg-muted/60 sm:mx-8 lg:mx-0",

                tier.isMostPop
                  ? ""
                  : tierIdx === 0
                    ? "rounded-t-3xl sm:rounded-b-none xl:rounded-bl-3xl xl:rounded-tr-none"
                    : "sm:rounded-t-none xl:rounded-bl-none xl:rounded-tr-3xl",
                "rounded-3xl p-8 ring-1 ring-border sm:p-10",
              )}
            >
              {tier.isMostPop && (
                <span className="absolute top-4 right-4 px-3 py-1 text-xs font-medium text-green-600 bg-green-100 border border-green-200 rounded-xl shadow-sm">
                  Recommended
                </span>
              )}
              <h3
                id={tier.name}
                className="text-base font-semibold leading-7 text-primary"
              >
                {tier.name}
              </h3>
              <p className="mt-4 flex items-baseline gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-foreground">
                  ${tier.isDiscounted ? tier.discountPrice : tier.price}
                </span>
                {tier.isDiscounted && (
                  <span className="text-3xl font-bold tracking-tight text-muted-foreground line-through">
                    ${tier.price}
                  </span>
                )}
              </p>
              <span className="text-xs font-semibold leading-6 text-muted-foreground mt-2">
                {tier.period}
              </span>
              <p className="mt-6 text-base leading-7 text-muted-foreground">
                {tier.description}
              </p>
              <p className="mt-6 text-base leading-7 text-muted-foreground font-medium">
                {tier.featureHeader}
              </p>
              <ul
                role="list"
                className="mt-2 space-y-3 text-sm leading-6 text-muted-foreground"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      aria-hidden="true"
                      className={cn(
                        tier.isMostPop
                          ? "h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full"
                          : "h-6 p-1 w-6 flex-none text-muted-foreground bg-muted rounded-full",
                      )}
                    />
                    {feature}
                  </li>
                ))}
              </ul>
              <CheckoutButton
                priceId={tier.priceId}
                mode={tier.mode as "subscription" | "payment" | "setup"}
                className={cn(
                  buttonVariants({
                    variant: tier.isMostPop ? "rainbow" : "outline",
                    size: "lg",
                  }),
                  "mt-6 w-full gap-2",
                )}
                coupon={tier.coupon ?? undefined}
              >
                {tier.cta}
              </CheckoutButton>
            </div>
          ))}
        </div>
        <div className="mt-8 max-w-lg mx-auto xl:max-w-4xl">
          <div className="bg-muted-background/60 shadow-2xl rounded-3xl p-8 ring-1 ring-border sm:p-10">
            <h3 className="text-base font-semibold leading-7 text-primary">
              Team License
            </h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span className="text-5xl font-bold tracking-tight text-foreground">
                $399
              </span>
              <span className="text-3xl font-bold tracking-tight text-muted-foreground line-through">
                $699
              </span>
            </p>
            <span className="text-xs font-semibold leading-6 text-muted-foreground mt-2">
              one-time payment
            </span>
            <p className="mt-6 text-base leading-7 text-muted-foreground">
              Beautifully crafted React + Tailwind CSS + Framer Motion templates
              for your team's next web project.
            </p>
            <p className="mt-6 text-base leading-7 text-muted-foreground font-medium">
              Everything in Personal, plus:
            </p>
            <ul
              role="list"
              className="mt-2 space-y-3 text-sm leading-6 text-muted-foreground"
            >
              <li className="flex gap-x-3">
                <CheckIcon
                  aria-hidden="true"
                  className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full"
                />
                Up to 25 developer licenses
              </li>
            </ul>
            <CheckoutButton
              priceId={licenses.team.priceId}
              coupon={licenses.team.coupon}
              mode="payment"
              className={cn(
                buttonVariants({
                  variant: "default",
                  size: "lg",
                }),
                "mt-6 w-full gap-2",
              )}
            >
              Get Team Access
            </CheckoutButton>
          </div>
        </div>
      </div>
    </section>
  );
}
