"use client";

import * as React from "react";
import { CheckIcon, XIcon } from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Slider } from "@/components/ui/slider";

export function ROICalculator() {
  const [designerTime, setDesignerTime] = React.useState(10);
  const [developerTime, setDeveloperTime] = React.useState(10);
  const [designerRate, setDesignerRate] = React.useState(100);
  const [developerRate, setDeveloperRate] = React.useState(150);

  const templateCost = 49;
  const templateSetupTime = 2.5;

  const calculateMetrics = () => {
    const designerCost = designerRate * designerTime * 8; // 8 hours per day
    const developerCost = developerRate * developerTime * 8; // 8 hours per day
    const totalCustomCost = designerCost + developerCost;
    const totalCustomTime = designerTime + developerTime;
    const costSavings = totalCustomCost - templateCost;
    const timeSavings = totalCustomTime - templateSetupTime;
    const roi = (costSavings / templateCost) * 100;

    return {
      totalCustomCost,
      totalCustomTime,
      costSavings,
      timeSavings,
      roi,
    };
  };
  const metrics = calculateMetrics();

  const customCost = metrics.totalCustomCost;
  const customTime = metrics.totalCustomTime;
  const savingsCost = metrics.costSavings;
  const savingsTime = metrics.timeSavings;
  const roi = metrics.roi;

  return (
    <div className="relative container mx-auto grid px-4 py-16 max-w-5xl gap-8">
      <div className="mx-auto max-w-5xl text-center">
        <h4 className="text-xl font-bold tracking-tight text-foreground">
          ROI Calculator
        </h4>

        <h2 className="text-4xl font-bold tracking-tight text-foreground sm:text-6xl">
          Calculate Your Savings
        </h2>

        <p className="mt-6 text-lg leading-8 text-muted-foreground">
          See how much time and money you can save by using our pre-built
          components and templates compared to building everything from scratch.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-8">
        <Card className="shadow-none">
          <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <CardHeader className="text-center px-0">
                <CardTitle className="text-3xl font-bold">
                  Custom Landing Page
                </CardTitle>
                <CardDescription className="text-lg">
                  Estimate how much it would cost to build these templates with
                  your team
                </CardDescription>
              </CardHeader>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="designer-time">Designer Time (days)</Label>
                  <div className="flex items-center gap-4">
                    <Slider
                      id="designer-time"
                      min={2}
                      max={25}
                      step={0.5}
                      value={[designerTime]}
                      onValueChange={(value) => setDesignerTime(value[0])}
                      className="flex-1"
                    />
                    <span className="w-16 text-right">{designerTime}d</span>
                  </div>
                </div>

                <div>
                  <Label htmlFor="developer-time">Developer Time (days)</Label>
                  <div className="flex items-center gap-4">
                    <Slider
                      id="developer-time"
                      min={2}
                      max={25}
                      step={0.5}
                      value={[developerTime]}
                      onValueChange={(value) => setDeveloperTime(value[0])}
                      className="flex-1"
                    />
                    <span className="w-16 text-right">{developerTime}d</span>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="designer-rate">
                      Designer Rate ($/hour)
                    </Label>
                    <div className="flex items-center gap-4">
                      <Input
                        id="designer-rate"
                        type="number"
                        min={50}
                        max={300}
                        step={10}
                        value={designerRate}
                        onChange={(e) =>
                          setDesignerRate(Number(e.target.value))
                        }
                        className="flex-1"
                      />
                    </div>
                  </div>

                  <div>
                    <Label htmlFor="developer-rate">
                      Developer Rate ($/hour)
                    </Label>
                    <div className="flex items-center gap-4">
                      <Input
                        id="developer-rate"
                        type="number"
                        min={50}
                        max={300}
                        step={10}
                        value={developerRate}
                        onChange={(e) =>
                          setDeveloperRate(Number(e.target.value))
                        }
                        className="flex-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-sm text-muted-foreground text-center mt-4">
                  Total estimated time:{" "}
                  {((designerTime + developerTime) / 5).toFixed(1)} weeks (
                  {(designerTime + developerTime).toFixed(1)} business days)
                  <br />
                  Total estimated cost: $
                  {(
                    designerRate * designerTime * 8 +
                    developerRate * developerTime * 8
                  ).toLocaleString()}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <div>
                <CardHeader className="text-center px-0">
                  <CardTitle className="text-3xl font-bold">
                    Magic UI Landing Page
                  </CardTitle>
                  <CardDescription className="text-lg">
                    Unlock massive benefits with our templates
                  </CardDescription>
                </CardHeader>
              </div>
              <CardContent className="px-0">
                <ul className="space-y-8">
                  <li className="flex items-center gap-6 bg-muted p-6 rounded-xl">
                    <CheckIcon className="h-8 p-1.5 w-8 flex-none text-green-600 bg-green-200 rounded-full" />
                    <span className="text-2xl font-semibold">
                      Reduce costs by{" "}
                      <span className="font-bold text-2xl text-green-600">
                        ${savingsCost.toLocaleString()}
                      </span>
                      . It's{" "}
                      <span className="font-bold text-2xl text-green-600">
                        {((savingsCost / customCost) * 100).toFixed(1)}%
                      </span>{" "}
                      cheaper than building it yourself.
                    </span>
                  </li>
                  <li className="flex items-center gap-6 bg-muted p-6 rounded-xl">
                    <CheckIcon className="h-8 p-1.5 w-8 flex-none text-green-600 bg-green-200 rounded-full" />
                    <span className="text-2xl font-semibold">
                      Launch{" "}
                      <span className="font-bold text-2xl text-green-600">
                        {savingsTime.toFixed(1)} business days sooner{" "}
                      </span>
                      on average. That's almost{" "}
                      <span className="font-bold text-2xl text-green-600">
                        {(savingsTime / 5).toFixed(1)} weeks earlier!
                      </span>
                    </span>
                  </li>
                  {/* <li className="flex items-center gap-6 bg-muted p-6 rounded-xl">
                    <CheckIcon
                      className="h-8 p-1.5 w-8 flex-none text-green-600 bg-green-200 rounded-full"
                    />
                    <span className="text-2xl font-semibold">
                      Achieve an incredible{" "}
                      <span className="font-bold text-3xl text-green-600">
                        {roi.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                        % ROI
                      </span>{" "}
                      on your investment
                    </span>
                  </li> */}
                </ul>
              </CardContent>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Card className="shadow-none">
          <CardHeader>
            <CardTitle className="font-bold">Magic UI Landing Page</CardTitle>
            <CardDescription>Benefits of using our templates</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full" />
              <span>
                <strong>Cost:</strong> Only $49
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full" />
              <span>
                <strong>Time:</strong> Same-day implementation
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full" />
              <span>
                <strong>Expertise:</strong> Engineered by UI/UX experts
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full" />
              <span>
                <strong>Quality:</strong> Thoroughly tested and optimized
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full" />
              <span>
                <strong>Skills:</strong> Includes professional animations
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full" />
              <span>
                <strong>Optimization:</strong> SEO-optimized with all best
                practices
              </span>
            </div>
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full" />
              <span>
                <strong>Responsive:</strong> Optimized for all devices
              </span>
            </div>
          </CardContent>
        </Card>
        <Card className="shadow-none">
          <CardHeader>
            <CardTitle className="font-bold">Custom Landing Page</CardTitle>
            <CardDescription>
              Common challenges when building your own landing page
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex items-center gap-2">
              <XIcon className="h-6 p-1 w-6 flex-none text-red-600 bg-red-200 rounded-full" />

              <span>
                <strong>Cost:</strong> ~${customCost.toLocaleString()} in
                developer and designer time
              </span>
            </div>
            <div className="flex items-center gap-2">
              <XIcon className="h-6 p-1 w-6 flex-none text-red-600 bg-red-200 rounded-full" />

              <span>
                <strong>Time:</strong> {customTime.toFixed(1)} weeks of
                development
              </span>
            </div>
            <div className="flex items-center gap-2">
              <XIcon className="h-6 p-1 w-6 flex-none text-red-600 bg-red-200 rounded-full" />
              <span>
                <strong>Expertise:</strong> Requires extensive UI/UX knowledge
              </span>
            </div>
            <div className="flex items-center gap-2">
              <XIcon className="h-6 p-1 w-6 flex-none text-red-600 bg-red-200 rounded-full" />
              <span>
                <strong>Quality:</strong> High risk of introducing bugs
              </span>
            </div>
            <div className="flex items-center gap-2">
              <XIcon className="h-6 p-1 w-6 flex-none text-red-600 bg-red-200 rounded-full" />
              <span>
                <strong>Skills:</strong> Requires animation experience
              </span>
            </div>
            <div className="flex items-center gap-2">
              <XIcon className="h-6 p-1 w-6 flex-none text-red-600 bg-red-200 rounded-full" />
              <span>
                <strong>Optimization:</strong> Requires SEO expertise
              </span>
            </div>
            <div className="flex items-center gap-2">
              <XIcon className="h-6 p-1 w-6 flex-none text-red-600 bg-red-200 rounded-full" />
              <span>
                <strong>Responsive:</strong> Requires custom styling for each
                device
              </span>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* <div className="text-center">
        <p className="text-xl mb-6">
          Stop reinventing the wheel. Leverage our battle-tested templates to
          accelerate your development and focus on what truly matters - your
          core business logic.
        </p>
      </div> */}
    </div>
  );
}
